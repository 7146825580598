<template>
  <div>
    <BookPicker
      v-model="selectedBook"
      :options="bookNames"
    />
    <StudentsAssignmentsList :assignments="selectedBookAssignments">
      <template #default="{ assignment }">
        <ResponsiveButton
          v-if="assignment.status === 'finished'"
          v-bind="reportButton"
          @click="assignmentNavigationAction(assignment)"
        />
        <ResponsiveButton
          v-else
          v-bind="startOrResumeButton"
          :label="getLabel(assignment)"
          @click="assignmentNavigationAction(assignment)"
        />
        <div />
      </template>
    </StudentsAssignmentsList>
  </div>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'

import assignmentNavigationAction from '@/mixins/assignmentNavigationAction'
import ResponsiveButton from '@/components/ResponsiveButton'
import BookPicker from '../BookPicker'
import StudentsAssignmentsList from './StudentsAssignmentsList'

export default {
  name: 'BookAssignments',
  components: {
    BookPicker,
    StudentsAssignmentsList,
    ResponsiveButton,
  },
  mixins: [ assignmentNavigationAction, mediaQueries ],
  props: {
    assignments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedBook: null,
    }
  },
  computed: {
    bookNames() {
      return this.assignments.map(({ name }) => name)
    },
    selectedBookAssignments() {
      if (this.selectedBook) {
        return this.assignments
          .find(({ name }) => name === this.selectedBook).assignments
      }

      return []
    },
    reportButton() {
      const button = {
        options: {
          variation: 'secondary',
          icon: 'check',
        },
        breakpoint: this.mq_m,
        label: this.$t('commons.seeReport'),
      }

      if (this.mq_m) {
        button.customStyle = {
          background: '#C5F0DE',
          color: '#12945D',
          border: 0,
          borderRadius: '50%',
        }
      }

      return button
    },
    startOrResumeButton() {
      return {
        options: {
          icon: 'play',
          iconLeft: 'play',
        },
        breakpoint: this.mq_m,
      }
    },
  },
  created() {
    [ this.selectedBook ] = this.bookNames
  },
  methods: {
    getLabel(assignment) {
      return assignment.status === 'start'
        ? this.$t('commons.start')
        : this.$t('commons.resume')
    },
  },
}
</script>

<style lang="scss" scoped>
.book-picker {
  margin-bottom: $size-m;
}
</style>
