<template>
  <div class="book-picker">
    <AdaptiveSelector
      :options="options"
      :selected-option="selectedOption"
      @select="$emit('select', $event)"
    />
  </div>
</template>

<script>
import AdaptiveSelector from '@/components/AdaptiveSelector/AdaptiveSelector'

export default {
  name: 'BookPicker',
  components: {
    AdaptiveSelector,
  },
  model: {
    prop: 'selectedOption',
    event: 'select',
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: String,
      default: null,
    },
  },
}
</script>
