<template>
  <div>
    <AssignmentsSectionSkeleton v-if="proposedAssignment.loading" />
    <template v-else>
      <AssignmentSectionLayout
        v-if="hasProposedQuestions"
        :title="$t('highSchool.subject.proposedAssignmentsSection.title')"
        :subtitle="$t('highSchool.subject.proposedAssignmentsSection.subtitle')"
      >
        <ProposedAssignment
          :lecture="lectureName"
          :assignment="proposedAssignment.data"
        />
      </AssignmentSectionLayout>
    </template>
    <AssignmentsSectionSkeleton v-if="assignments.loading" />
    <template v-else>
      <AssignmentSectionLayout
        v-if="hasTeachersAssignments"
        :title="$t('highSchool.subject.schoolAssignmentsSection.title')"
      >
        <StudentsAssignmentsList
          :assignments="teachersAssignments"
        >
          <template #default="{ assignment }">
            <div>
              <ResponsiveButton
                v-if="assignment.status === 'finished'"
                v-bind="reportButton"
                @click="assignmentNavigationAction(assignment)"
              />
              <ResponsiveButton
                v-else
                v-bind="startOrResumeButton"
                :label="getLabel(assignment)"
                @click="assignmentNavigationAction(assignment)"
              />
            </div>
          </template>
        </StudentsAssignmentsList>
      </AssignmentSectionLayout>
      <AssignmentSectionLayout
        v-if="hasBookAssignments"
        :title="$t('highSchool.subject.bookAssignmentsSection.title')"
        :subtitle="$t('highSchool.subject.bookAssignmentsSection.subtitle')"
      >
        <BookAssignments :assignments="bookAssignments" />
      </AssignmentSectionLayout>
      <EmptyState
        v-if="noAssignments"
        :title="emptyState.title"
        :description="emptyState.description"
        image="empty-activities"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { isNotEmpty } from '@/utils/arrays'
import mediaQueries from '@/mixins/mediaQueries'
import proposedAssignmentApi from '@/service/proposedAssignment'
import api from '@/service/assignments'
import assignmentNavigationAction from '@/mixins/assignmentNavigationAction'
import AssignmentSectionLayout from '@/components/Assignments/AssignmentSectionLayout'
import BookAssignments from '@/components/Assignments/BookAssignments'
import AssignmentsSectionSkeleton from '@/components/Assignments/AssignmentsSectionSkeleton'
import StudentsAssignmentsList from '@/components/Assignments/StudentsAssignmentsList'
import EmptyState from '@/components/EmptyState'
import ResponsiveButton from '@/components/ResponsiveButton'
import ProposedAssignment from './partials/ProposedAssignment'

export default {
  name: 'Lecture',
  components: {
    AssignmentSectionLayout,
    BookAssignments,
    AssignmentsSectionSkeleton,
    StudentsAssignmentsList,
    EmptyState,
    ProposedAssignment,
    ResponsiveButton,
  },
  mixins: [ assignmentNavigationAction, mediaQueries ],
  props: {
    chapterId: {
      type: Number,
      default: null,
    },
    lectureId: {
      type: Number,
      required: true,
    },
    subjectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      teachersAssignments: [],
      bookAssignments: [],
      proposedAssignment: {
        data: null,
        loading: false,
        error: null,
      },
      assignments: {
        loading: false,
        error: null,
      },
    }
  },
  computed: {
    ...mapGetters([ 'lecture', 'subjectLectures' ]),
    emptyState() {
      return {
        title: this.$t('highSchool.subject.schoolAssignmentsSection.empty.title'),
        description: this.$t('highSchool.subject.schoolAssignmentsSection.empty.description', {
          lecture: this.lecture.data?.name,
        }),
      }
    },
    noAssignments() {
      return !this.hasBookAssignments && !this.hasTeachersAssignments
    },
    reportButton() {
      const button = {
        options: {
          variation: 'secondary',
          icon: 'check',
        },
        breakpoint: this.mq_m,
        label: this.$t('commons.seeReport'),
      }

      if (this.mq_m) {
        button.customStyle = {
          background: '#C5F0DE',
          color: '#12945D',
          border: 0,
          borderRadius: '50%',
        }
      }

      return button
    },
    startOrResumeButton() {
      return {
        options: {
          icon: 'play',
          iconLeft: 'play',
        },
        breakpoint: this.mq_m,
      }
    },
    lectureName() {
      const lectures = this.subjectLectures.data

      if (!lectures) {
        return ''
      }

      return lectures.find(({ id }) => id === this.lectureId).name
    },
    hasTeachersAssignments() {
      return isNotEmpty(this.teachersAssignments)
    },
    hasBookAssignments() {
      return this.bookAssignments !== null && isNotEmpty(this.bookAssignments)
    },
    hasProposedQuestions() {
      return Boolean(this.proposedAssignment.data?.hasProposedQuestions)
    },
  },
  watch: {
    lectureId() {
      this.fetchAssignments()
    },
  },
  async created() {
    await this.fetchAssignments()
    this.fetchProposedAssignmentInfo()

    if (this.chapterId) {
      this.handleChapterAction()
    }
  },
  methods: {
    async fetchAssignments() {
      this.assignments.loading = true

      try {
        const {
          data: { bookAssignments, teachersAssignments },
        } = await api.getAssignments(this.lectureId)

        this.bookAssignments = bookAssignments
        this.teachersAssignments = teachersAssignments.assignments
      } catch (error) {
        this.assignments.error = error
      } finally {
        this.assignments.loading = false
      }
    },
    async fetchProposedAssignmentInfo() {
      const { subjectId } = this

      this.proposedAssignment.loading = true

      try {
        const { data } = await proposedAssignmentApi.getProposedAssignmentInfo(subjectId)
        this.proposedAssignment.data = data
      } catch (error) {
        this.proposedAssignment.error = error
      } finally {
        this.proposedAssignment.loading = false
      }
    },
    getLabel(assignment) {
      return assignment.status === 'start'
        ? this.$t('commons.start')
        : this.$t('commons.resume')
    },
    handleChapterAction() {
      const routeQueryParams = { ...this.$route.query }
      const { chapterId, bookAssignments } = this
      const allAssignments = []

      bookAssignments.forEach((book) => {
        allAssignments.push(...book.assignments)
      })

      const [ selectedChapter ] = allAssignments
        .filter(({ id }) => id === chapterId)
        ?.map((chapter) => ({
          ...chapter,
          isUnreleased: chapter.status === 'unreleased',
        }))

      if (!selectedChapter.isUnreleased) {
        delete routeQueryParams.chapterId

        this.$router.push({
          name: 'lecture-question',
          query: routeQueryParams,
          params: {
            lectureId: this.lectureId,
            subjectId: this.subjectId,
            questionnaireCode: selectedChapter.questionnaireCode,
          },
        })
      }
    },
  },
}
</script>
