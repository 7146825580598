export default {
  methods: {
    assignmentNavigationAction({ status, questionnaireCode }) {
      if (status === 'finished') {
        this.$router.push({
          name: 'lecture-report',
          params: {
            questionnaireCode,
          },
        })
      } else if (status !== 'unreleased') {
        this.$router.push({
          name: 'lecture-question',
          params: {
            questionnaireCode,
          },
        })
      }
    },
  },
}
