<template>
  <i>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172
        3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
        stroke="#666E75"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.9984 21.0004L16.6484 16.6504"
        stroke="#666E75"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: 'HistoricIcon',
}
</script>
