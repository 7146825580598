import http from './http'

export default {
  finishAssignment: (assignmentCode) => (
    http.put(`assignments/${assignmentCode}`, {
      status: 'finished',
    })
  ),
  getAssignments: (lectureId) => http.get(`v2/lectures/${lectureId}/assignments`),
}
