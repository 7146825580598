<template>
  <div class="proposed-assignment">
    <AssignmentsListItem
      :key="mappedAssignment.id"
      :assignment="mappedAssignment"
      variation="secondary"
    >
      <template
        v-if="badgeInfo"
        #info
      >
        <div class="proposed-assignment__details__with-badge">
          <span>{{ mappedAssignment.subtitle }}</span>
          <s-badge v-bind="badgeInfo">
            {{ badgeInfo.message }}
          </s-badge>
          <h6 class="proposed-assignment__details__title">
            {{ mappedAssignment.title }}
          </h6>
        </div>
      </template>
      <template>
        <div
          v-for="({
            isVisible,
            buttonOptions,
            buttonAction,
            buttonClasses,
            buttonText,
            icon
          }, index) in actionButtonsList"
          :key="`${icon}-${index}`"
        >
          <s-button
            v-if="isVisible"
            v-bind="buttonOptions"
            :class="buttonClasses"
            @click="buttonAction"
          >
            <template #icon>
              <component :is="icon" />
            </template>
            <template v-if="buttonText">
              {{ buttonText }}
            </template>
          </s-button>
        </div>
      </template>
    </assignmentslistitem>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import truncateText from '@/mixins/truncateText'
import AssignmentsListItem from '@/components/Assignments/AssignmentsListItem'
import mediaQueries from '@/mixins/mediaQueries'
import ReinforceIcon from './ReinforceIcon'
import HistoricIcon from './HistoricIcon'

export default {
  name: 'ProposedAssignment',
  components: {
    ReinforceIcon,
    HistoricIcon,
    AssignmentsListItem,
  },
  mixins: [ mediaQueries, truncateText ],
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    lecture: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      subject: ({ subjects }) => subjects.subject,
    }),
    hasHistoric() {
      return this.assignment.hasHistoric
    },
    badgeInfo() {
      if (this.hasHistoric) {
        return null
      }

      return {
        message: this.$t('commons.new').toUpperCase(),
        size: 'small',
        variation: 'success',
      }
    },
    actionButtonsList() {
      const { assignment: { hasProposedQuestions }, hasHistoric } = this
      const buttons = []
      const buttonClasses = [
        'sas-button__icon',
        '--medium',
        'proposed-assignment__actions',
      ]
      const reinforceButton = {
        buttonClasses,
        buttonText: this.mq_l__mf ? this.$t('commons.reinforce') : '',
        buttonAction: this.goToSearchAssignment,
        isVisible: true,
        icon: ReinforceIcon,
        buttonOptions: { variation: 'primary' },
      }

      if (hasHistoric) {
        buttonClasses.push('--margin-right')
        buttons.push({
          isVisible: this.hasHistoric,
          buttonClasses,
          buttonText: this.mq_l__mf ? this.$t('commons.historic') : '',
          buttonOptions: { variation: 'secondary' },
          buttonAction: this.goToHistoric,
          icon: HistoricIcon,
        })
      }

      if (hasProposedQuestions) {
        buttons.push(reinforceButton)
      } else {
        buttons.push({
          ...reinforceButton,
          buttonOptions: {
            background: 'rgba(102, 110, 117)',
            disabled: true,
          },
        })
      }

      return buttons
    },
    mappedAssignment() {
      return {
        subtitle: this.truncateText(this.assignment.name),
        title: this.subject.data.name,
        id: this.assignment.name.split('').length,
        status: null,
      }
    },
  },
  methods: {
    goToSearchAssignment() {
      this.$trackEvent({
        category: this.$track.category.proposedQuestions,
        action: this.$track.action.reinforce,
        label: this.lecture,
      })

      this.$router.push({ name: 'search-proposed-assignments' })
    },
    goToHistoric() {
      this.$router.push({ name: 'proposed-assignment-historic' })
    },
  },
}
</script>
<style lang="scss" scoped>
.proposed-assignment {
  &__details {
    &__title {
      margin-bottom: $size-s;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include mq-m--mf {
        margin-bottom: 0;
      }
    }

    &__with-badge {
      .sas-badge {
        margin-left: $size-xs;
      }
    }
  }
}

.--margin-right {
  margin-right: 22px;
}

::v-deep .sas-button {
  i {
    @include mq_m__mf {
      margin-right: $size-xs;
    }
  }

  @include mq-m {
    margin-bottom: $size-xs;
  }

  &:disabled {
    opacity: 1;
  }
}
</style>
