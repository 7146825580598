<template>
  <div>
    <s-button
      v-if="isBreakpoint"
      :style="customStyle"
      v-bind="responsiveButtonOptions"
      @click="$emit('click')"
    />
    <s-button
      v-else
      :style="customStyle"
      v-bind="responsiveButtonOptions"
      @click="$emit('click')"
    >
      {{ label }}
    </s-button>
  </div>
</template>

<script>
export default {
  name: 'ResponsiveButton',
  props: {
    breakpoint: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      responsiveButtonOptions: {},
    }
  },
  computed: {
    isBreakpoint() {
      return this.breakpoint
    },
  },
  watch: {
    isBreakpoint: {
      immediate: true,
      handler() {
        const options = { ...this.options }

        if (this.isBreakpoint) {
          this.responsiveButtonOptions = {
            ...options,
            iconLeft: null,
            iconRight: null,
          }
        } else {
          this.responsiveButtonOptions = {
            ...options,
            icon: null,
          }
        }
      },
    },
  },
}
</script>
