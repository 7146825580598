var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"proposed-assignment"},[_c('AssignmentsListItem',{key:_vm.mappedAssignment.id,attrs:{"assignment":_vm.mappedAssignment,"variation":"secondary"},scopedSlots:_vm._u([(_vm.badgeInfo)?{key:"info",fn:function(){return [_c('div',{staticClass:"proposed-assignment__details__with-badge"},[_c('span',[_vm._v(_vm._s(_vm.mappedAssignment.subtitle))]),_c('s-badge',_vm._b({},'s-badge',_vm.badgeInfo,false),[_vm._v(" "+_vm._s(_vm.badgeInfo.message)+" ")]),_c('h6',{staticClass:"proposed-assignment__details__title"},[_vm._v(" "+_vm._s(_vm.mappedAssignment.title)+" ")])],1)]},proxy:true}:null],null,true)},[_vm._l((_vm.actionButtonsList),function(ref,index){
        var isVisible = ref.isVisible;
        var buttonOptions = ref.buttonOptions;
        var buttonAction = ref.buttonAction;
        var buttonClasses = ref.buttonClasses;
        var buttonText = ref.buttonText;
        var icon = ref.icon;
return _c('div',{key:(icon + "-" + index)},[(isVisible)?_c('s-button',_vm._b({class:buttonClasses,on:{"click":buttonAction},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(icon,{tag:"component"})]},proxy:true}],null,true)},'s-button',buttonOptions,false),[(buttonText)?[_vm._v(" "+_vm._s(buttonText)+" ")]:_vm._e()],2):_vm._e()],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }