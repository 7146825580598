<template>
  <i class="reinforce-icon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3727 2.58995L12.6273 1.33528C12.9738 0.988809 13.5356 0.988809
        13.882 1.33528L22.6647 10.118C23.0112 10.4645 23.0112 11.0262 22.6647
        11.3727L21.4101 12.6273C21.0636 12.9738 20.5019 12.9738 20.1554
        12.6273L11.3727 3.84462C11.0262 3.49815 11.0262 2.93642 11.3727
        2.58995Z"
        fill="white"
      />
      <path
        d="M2.58997 11.3727L1.3353 12.6274C0.988833 12.9738 0.988833 13.5356
        1.3353 13.882L10.118 22.6647C10.4645 23.0112 11.0262 23.0112 11.3727
        22.6647L12.6274 21.4101C12.9738 21.0636 12.9738 20.5019 12.6274
        20.1554L3.84465 11.3727C3.49818 11.0262 2.93644 11.0262 2.58997
        11.3727Z"
        fill="white"
      />
      <path
        d="M15.3159 1.51452L16.5706 0.259851C16.917 -0.0866174 17.4788
        -0.0866167 17.8252 0.259851L23.7401 6.17473C24.0866 6.5212 24.0866
        7.08294 23.7401 7.42941L22.4854 8.68408L15.3159 1.51452Z"
        fill="white"
      />
      <path
        d="M1.51452 15.3159L0.259851 16.5706C-0.0866174 16.9171 -0.0866167
        17.4788 0.259851 17.8253L6.17473 23.7401C6.5212 24.0866 7.08294 24.0866
        7.42941 23.7401L8.68408 22.4855L1.51452 15.3159Z"
        fill="white"
      />
      <path
        d="M7.4294 13.7028L13.7028 7.42939L16.5706 10.2972L10.2972 16.5706L7.4294 13.7028Z"
        fill="white"
      />
    </svg>

  </i>
</template>

<script>
export default {
  name: 'ReinforceIcon',
}
</script>
